import * as React from 'react';
import Chip from '@mui/material/Chip';
import { TbPaperBag } from "react-icons/tb";


export default function AllFoodCategoriesChip(props) {

  const sendCategoryID = () => {
    props.getCategoryID(0)
  }
  return (
    <Chip icon={<TbPaperBag style={{fontSize:17, color:'white'}} />} label='All' sx={{backgroundColor:'rgb(6 149 218)',fontSize:17, color:'white', margin:'10px', boxShadow:'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset'}} onClick={sendCategoryID} />
  );
}
