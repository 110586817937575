import React, { useEffect, useState } from 'react'
import Dashboard from '../../layout/dashboard/dashboardComponent'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import classes from '../orders/ordersPage.module.css'
import { secondaryColour } from '../../utils/colors';
import SubscribersDataTable from '../../components/subscribersDataTable';
import TextField from '@mui/material/TextField';
import styleclasses from '../subscribers/SubscribersPage.module.css'
import LoadingButton from '@mui/lab/LoadingButton';
import { getAllSubscribers, getOneSubscriberBalance } from '../../services/productServices';
import SubscriberInfoCard from '../../components/subscriberInfoCard';
import AlertComponent from '../../components/alertComponent';
import LoaderDialogue from '../../components/loaderDialogueComponent';



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




function SubscribersPage() {
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [allSubscribers, setAllSubscribers] = useState([])
    const [cardCode, setCardCode] = useState('')
    const [openSubscriberInfoCard, setOpenSubscriberInfoCard] = useState(false)
    const [subscriberInfoCardData, setSubscriberInfoCardData] = useState({})
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
    const [loaderDialogueText, setLoaderDialogueText] = useState('')

    const handleClick = () => { }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    };
    const handleCardCodeTextField = (event) => {

        setCardCode(event.target.value)
        
    }

    const closeSubscriberInfoCard = () => {
        setOpenSubscriberInfoCard(false)
    }

    const closeAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const handleForm = (event) => {
        event.preventDefault()
        setLoading(true)
        getOneSubscriberBalance(cardCode).then(
            response => {
                
                if (response.data.status === "fail") {
                    setLoading(false)
                    setOpenSubscriberInfoCard(false)
                    setAlertMessage("Subscriber not found. You may have entered a wrong ID")
                    setOpenAlert(true)
                    
                }else{
                setSubscriberInfoCardData(response.data)
                setLoading(false)
                setOpenSubscriberInfoCard(true)}
            }
        ).catch(error => {
            if (error.response.status === 401) {
                setOpenLoaderDialogue(false)
                setOpenAlert(true)
                setAlertMessage('Your token has expired')
                Cookies.remove('loggedState')
                Cookies.remove('bearer')
                navigate('/ ')
                localStorage.clear()
            }
            else {
                setOpenLoaderDialogue(false)
                setOpenAlert(true)
                setAlertMessage('POS encountered an error. Please try again')

            }
        })
    }

    useEffect(() => {

        const storeLogger = localStorage.getItem('userStoreID')
        if (!storeLogger) {
            navigate("/select")
        }

        const getEverySubscriber = async () => {
            try {
                if (localStorage.getItem('allSubscribers')) {
                    const subscribers = JSON.parse(localStorage.getItem('allSubscribers'));
                    setAllSubscribers([...subscribers]);
                } else {
                    setOpenLoaderDialogue(true)
                    setLoaderDialogueText("POS is getting all subscribers...")
                    const response = await getAllSubscribers();
                    localStorage.setItem('allSubscribers', JSON.stringify(response.data));
                    setAllSubscribers(response.data);
                    setOpenLoaderDialogue(false)
                }
            } catch (error) {
                setOpenLoaderDialogue(false)
                setOpenAlert(true)
                setOpenLoaderDialogue(false)
                setAlertMessage('POS encountered an error. Please try again')

            }
        };

        getEverySubscriber();
    }, []);

    return (
        <Dashboard>
            <Grid container spacing={2} sx={{ p: 3 }}>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <div className="">
                                <h3 style={{color:secondaryColour}}>Check Subscriber Balance</h3>
                            </div>

                            <form className={styleclasses.textboxContainer} onSubmit={handleForm}>
                                <TextField id="outlined-basic" label="Enter Card Code" onChange={handleCardCodeTextField} variant="outlined" required={true} />
                                <LoadingButton
                                    color="primary"
                                    size='large'
                                    loading={loading}
                                    loadingPosition="start"
                                    sx={{ marginLeft: 3 }}
                                    variant="contained"
                                    type='submit'
                                >
                                    <span >Check Balance</span>
                                </LoadingButton>

                            </form>
                        </CardContent>
                        <CardActions>

                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

            <div className={classes.dataContainer}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Subscribers" {...a11yProps(0)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <SubscribersDataTable tableData={allSubscribers} />
                    </CustomTabPanel>
                </Box>
                <SubscriberInfoCard openCard={openSubscriberInfoCard} cardData={subscriberInfoCardData} closeCard={closeSubscriberInfoCard} />
            </div>
            <AlertComponent open={openAlert} messgae={alertMessage} close={closeAlert} ></AlertComponent>
            <LoaderDialogue open={openLoaderDialogue} message={loaderDialogueText} />
        </Dashboard>
    )
}

export default SubscribersPage