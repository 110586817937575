import * as React from 'react';
import { DataGrid} from '@mui/x-data-grid';
import { HiOutlineDocumentDownload } from "react-icons/hi";
import Button from '@mui/material/Button';
import LoaderDialogue from './loaderDialogueComponent';
import { printShift } from '../services/storeServices';
import ShiftsReportDialogue from './shiftsReportDialogue';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


export default function ShiftsDataTable(props) {

  const [openReportDialogue, setOpenReportDialogue] = useState(false)
  const [reportData, setReportData] = useState([])
  const [registerDetails, setRegisterDetails] = useState({})
  const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
  const [loaderDialogueText, setLoaderDialogueText] = useState('')

  const navigate = useNavigate()
  const printShiftReport = (registerID, mealplanSales,refunds,date,cashSale) => {
    setOpenLoaderDialogue(true)
    setLoaderDialogueText("POS is generating your report, please wait....")
    setRegisterDetails({registerID,mealplanSales,refunds})
    printShift(registerID).then(
      response=> {
        if(response.status == 200){
          
          setReportData([...response.data])
          setOpenLoaderDialogue(false)
          // setOpenReportDialogue(true)
          localStorage.setItem("registerData",JSON.stringify(response.data))
          localStorage.setItem("registerDataDate",date)
          localStorage.setItem("registerDataRefunds",refunds)
          localStorage.setItem("registerNumber",registerID)
          localStorage.setItem("registerMealPlanSales",mealplanSales)
          localStorage.setItem("registerCashSales",cashSale)
          navigate("/printregister")
        }
        
      }
    ).catch(
      error=>{
        if(error.response && error.response.status === 401){
          navigate("/")
        }
      }
    )
  }

  const closeReportDialogue = () => {
    setOpenReportDialogue(false)
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'registerId', headerName: 'Register ID', width: 200 },
    { field: 'mealplan', headerName: 'Meal Plan Sale(GHC)', width: 200 },
    { field: 'cash', headerName: 'Cash Sale(GHC)', width: 200 },
    {
      field: 'refund',
      headerName: 'Refunded(GHC)',
      type: 'string',
      width: 200,
    },
    {
      field: 'date',
      headerName: 'Date',
      type: 'string',
      width: 200,
    },
    {
      field: 'print',
      headerName: 'Print Summary',
      width: 200,
      renderCell: (params) => (
        <Button variant="outlined" startIcon={<HiOutlineDocumentDownload />} onClick={()=>printShiftReport(params.row.registerId,params.row.mealplan,params.row.refund,params.row.date,params.row.cash)}>
          Print
        </Button>
      ),
    },
  ];

  const rows = [
  ];
  props.tableData.map(
    (item,index)=>{
      const rowItem = {id:index+1,registerId:item.id,mealplan:item.credit.toFixed(2),cash:item.cash.toFixed(2),refund:item.cancelled.toFixed(2),date:item.opening_time}
      rows.push(rowItem)
    }
  )
  return (
    <div style={{ height: 400, width: '100%' , backgroundColor:"white"}}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
      <ShiftsReportDialogue open={openReportDialogue} tableData={reportData} registerDetails ={registerDetails} close={closeReportDialogue}/>
      <LoaderDialogue open={openLoaderDialogue} message={loaderDialogueText}/>
    </div>
  );
}
