import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { Provider } from 'react-redux'
import { store } from './state/redux/store.js'
import mixpanel from 'mixpanel-browser';
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://51a68f3834fb0ac29056decab2e6aa1b@o4507283273744384.ingest.de.sentry.io/4507283288227920",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
// Initialize Mixpanel
mixpanel.init('41b82abb083ef8de774eb33eb13399a5', { debug: true, track_pageview: true, persistence: 'localStorage' });
mixpanel.track('App Loaded');
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>

  </React.StrictMode>,
)
