import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../utils/colors'

//get all subscribers
export const getAllSubscribers = async() => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type": "application/json",
              },
            
        }
    )

    return await instance.get('getAllSubscribers')
}

//get one subscriber
export const getOneSubscriberBalance = async(subscriberID) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
              },
        }
    )

    return await instance.get(`/getCurrentBalance2/${subscriberID}`)
}

//get users store settings
export const loadStoreSettings = async(storeId) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
        }
    )

    return await instance.get(`get_store_settings/${storeId}`)
}

//get users store products
export const loadStoreProducts = async(storeId) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
        }
    )

    return await instance.get(`get_store_products/${storeId}`)
}

//get products categories
export const loadProductCategories = async(storeId) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
        }
    )

    return await instance.get(`get_store_product_categories/${storeId}`)
}