import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LoaderDialogue from './loaderDialogueComponent';



export default function ShiftsReportDialogue(props) {
  const [open, setOpen] = React.useState(false);

  function createData(
    product,quantity, sales
  ) {
    return {  product,quantity, sales };
  }
  
  const rows = [];

  const sortedTableData = [...props.tableData].sort((a, b) => {
   
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    // Compare the names
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0; 
  });
  sortedTableData.map(item=>{
    rows.push(createData(item.name, item.total_quantity, item.total_cost))
  })


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.close();
  };

  const printReceipt = () =>{
    window.print()
  }

  return (
    <React.Fragment>
      
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Register #${props.registerDetails.registerID}`}
        </DialogTitle>
        <DialogContent>
            <p style={{marginBottom:"5px"}}>
            Store: {localStorage.getItem("storeName")}
            </p>
            <p style={{marginBottom:"5px"}}>
            Cashier: {localStorage.getItem("username")}
            </p>
            
            <p style={{marginBottom:"5px"}}>
            Credit Sales: {props.registerDetails.mealplanSales}
            </p>

            <p style={{marginBottom:"5px"}}>
            Total Cancelled Sales: {props.registerDetails.refunds}
            </p>

            <p style={{marginBottom:"5px"}}>
            Total Sales: 
            </p>
            <p style={{marginBottom:"5px"}}>
            Closing Date: 
            </p>
          
          <div className="">
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Product</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Sales</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              
              <TableCell align="right">{row.product}</TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right">{row.sales}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={printReceipt}>Print</Button>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>

        {/* Print-specific CSS */}
      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            #alert-dialog-title,
            #alert-dialog-description,
            .MuiPaper-root,
            .MuiDialogActions-root {
              visibility: visible !important;
            }
            #alert-dialog-title {
              margin-bottom: 10px;
            }
            .MuiTable-root {
              margin-bottom: 20px;
            }
          }
        `}
      </style>
      </Dialog>
    </React.Fragment>
  );
}
