import React from 'react'
import Dashboard from '../../layout/dashboard/dashboardComponent'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import classes from '../orders/ordersPage.module.css'
import ShiftsDataTable from '../../components/shiftsDataTableComponent';
import { getSales } from '../../services/storeServices';
import { useEffect, useState } from 'react';
import { getMyRegisters } from '../../services/storeServices'
import LoaderDialogue from '../../components/loaderDialogueComponent';
import AlertComponent from '../../components/alertComponent';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';




function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




function ShiftsPage() {
    const [value, setValue] = React.useState(0);
    const storeID = localStorage.getItem("userStoreID")
    const registerID = localStorage.getItem("registerID")
    const [registerData, setRegisterData] = useState([])
    const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
    const [loaderDialogueText, setLoaderDialogueText] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")

    const navigate = useNavigate()
    const getAllMyRegisters = () => {
        setOpenLoaderDialogue(true)
        setLoaderDialogueText("POS is getting your registers....")
        getMyRegisters(localStorage.getItem("userID")).then(
            response => {
                
                if (response.status == 200) {
                    setRegisterData(response.data)
                    setOpenLoaderDialogue(false)
                }

            }
        ).catch(error => {
           
            if (error.response && error.response.status === 401) {
                setOpenLoaderDialogue(false)
                setOpenAlert(true)
                setAlertMessage('Your token has expired')
                Cookies.remove('loggedState')
                Cookies.remove('bearer')
                navigate('/')
                localStorage.clear()
            }
            else {
                setOpenLoaderDialogue(false)
                setOpenAlert(true)
                setAlertMessage('POS encountered an error. Please try again')

            }



        })
    }

    useEffect(() => {
        const storeLogger = localStorage.getItem('userStoreID')
        if (!storeLogger) {
            navigate("/select")
        }

        getAllMyRegisters()

    }, [])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Dashboard>
            <div className={classes.dataContainer}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Sales" {...a11yProps(0)} />


                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <ShiftsDataTable tableData={registerData} />
                    </CustomTabPanel>


                </Box>
                <LoaderDialogue open={openLoaderDialogue} message={loaderDialogueText} />
                <AlertComponent open={openAlert} message={alertMessage}></AlertComponent>
            </div>
        </Dashboard>
    )
}

export default ShiftsPage