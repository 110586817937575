import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../utils/colors'
import { newAxiosInstance } from '../utils/generalFunctions'

const createAxiosInstance = () => {
    return axios.create({
      baseURL: baseURL,
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('bearer')}`
      },
    });
  };

const instance = createAxiosInstance()
const scannerInstance = axios.create(
    {
        baseURL:"http://localhost:1234/",
        headers: {
            "Content-type":'application/json',
          },
    }
)

const oldLiveInstance = axios.create(
    {
        baseURL:"https://mplan.ashesi.edu.gh/API/api/",
        headers: {
            "Content-type":'application/json',
          },
    }
)




export const checkRegisterStatus = async(storeID,userID) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
        }
    )

    return await instance.get(`check_register_status/${storeID}/${userID}`)
}

export const openRegister = async(storeID,userID,openingCash) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
        }
    )

    return await instance.get(`open_register/${storeID}/${userID}/${openingCash}`)
}

export const completeCheckout = async(data) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
            body:JSON.stringify(data)
        }
    )

    return await instance.put('make_order',JSON.stringify(data))
}

export const getExpectedCash = async(data) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
        }
    )
    return await instance.post('get_expected_cash',JSON.stringify(data))
}

export const closeRegister = async(registerID,closingCash, expectedCash, notes) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
            
        }
    )

    return await instance.put(`close_register/${registerID}`,JSON.stringify({registerID, closingCash, expectedCash, notes}))
}

export const printShift = async(registerID) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
         
        }
    )
    return await instance.get(`print_shift/${registerID}`)
}

export const getSales = async(storeID,registerID) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
         
        }
    )
    return await instance.get(`get_sales/${storeID}/${registerID}`)
}

export const makeCaptureRequest = async() => {
    const instance = axios.create(
        {
            baseURL:"http://localhost:1234",
            
        }
    )

    return await instance.post("/?type=capture&temp1=ss&temp2=ss&temp3=ss")
}


export const makeVerificationRequest = async(temp1, temp2, temp3) => {
    const instance = axios.create(
        {
            baseURL:"http://localhost:1234",
        }
    )
    

    return await instance.post(`/?type=verify&temp1=${temp1}&temp2=${temp2}&temp3=${temp3}`)
}

export const getSalesReport = async(storeID) => {

    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
           
        }
    )

    return await instance.get(`/getSalesReport/${storeID}`)
}

export const getFundersReport = async(date,storeID) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
           
        }
    )

    return await instance.get(`getFunders/${date}/${storeID}`)
}

export const getAllSalesReport = async(date,storeID) => {

    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
           
        }
    )

    return await instance.get(`print_day_sales_category/${date}/${storeID}/All`)
}

export const getMyRegisters = async(userid) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
        }
    )
    
    return await instance.get(`getMyRegisters/${userid}`)
}

export const cancelOrder = async(orderID) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
        }
    )
    
    return await instance.put(`cancel_order/${orderID}`,JSON.stringify({orderID:orderID}))
}

export const getCancelledOrders = async(storeID, registerID) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
         
        }
    )
    
    return await instance.get(`get_cancelled_orders/${storeID}/${registerID}`)
}

export const logOut = async() => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
         
        }
    )
    
    return await instance.post(`logout`)
}
export const captureprint = () =>{
        
            fetch('http://localhost:1234/?type=capture&temp1=ss&temp2=ss&temp3=ss', {
  method: 'POST'
}).then(response => response.json())
  .then(data => console.log(data))}