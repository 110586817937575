import * as React from 'react';
import { DataGrid} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { TiCancelOutline } from "react-icons/ti";
import CancelOrderDialogue from './cancelOrderDialogueComponent.jsx';





export default function SubscribersDataTable(props) {

  const loadAllSales = () => {
    props.loadSales()
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    

    { field: 'subscriberID', headerName: 'Subscriber ID', width: 200 },
    {
      field: 'lastname',
      headerName: 'Last Name',
      type: 'string',
      width: 200,
    },
    {
      field: 'firstname',
      headerName: 'First Name',
      type: 'string',
      width: 200,
    },
   
    {
      field: 'dailylimit',
      headerName: 'Daily Limit (GHC)',
      type: 'string',
      width: 200,
    },
    {
      field: 'balance',
      headerName: 'Account Balance (GHC)',
      type: 'string',
      width: 200,
    },
  
   
  ];

  const rows = [
  ];
  props.tableData.map(
    (item,index)=>{
      const rowItem = {id:index, subscriberID:item.card_code,firstname:item.firstname,lastname:item.lastname,dailylimit: item.daily_spending_limit.toFixed(2), balance:item.amount.toFixed(2)}
      rows.push(rowItem)
    }
  )
  return (
    <div style={{ height: 400, width: '95%' , backgroundColor:"white"}}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div>
  );
}
