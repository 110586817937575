import React from 'react'
import classes from "../login/LoginPage.module.css"
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { AiOutlineLogin } from "react-icons/ai";
import { useState } from 'react';
import { logUserIn } from '../../services/userServices';
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


function LoginPage() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [credentialError, setCredentialError] = useState(false)
    const [credentialErrorMessage, setCredentialErrorMessage] = useState("")

    const navigate = useNavigate()
    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleForm = (event) => {
        event.preventDefault()
        setLoading(true)
        logUserIn(email,password).then(
            response =>{
                Cookies.set('bearer', response.data.access_token)
                Cookies.set('loggedState','loggedIn')
                localStorage.setItem('userID', response.data.user.id)
                localStorage.setItem('username', `${response.data.user.first_name}`)
                setCredentialError(false)
                setLoading(false)
                navigate('/select')
            }
        ).catch(error=>{

            
            if(error){
                setCredentialError(true)
                setCredentialErrorMessage("Error Signing In")
                setLoading(false)
            }
        })
    }

    useEffect(()=>{
        const logger = Cookies.get('loggedState')
        if(logger === "loggedIn"){
            navigate("/select")
        }
    },[])
    
    return (
        <div className={classes.container}>
            
            <Typography sx={{ marginTop: '10px' }}></Typography>
            <div className={classes.logoContainer}>
                
            </div>
            <div className={classes.formContainer}>
                <div className={classes.headerImageContainer}>
                    <p>Meal Plan  <span>POS</span></p>
                </div>
                <div className="">
                    <Typography sx={{ marginTop: '10px' }}>Sign In to continue</Typography>

                </div>

                <form action="" onSubmit={handleForm}>
                <TextField className ={classes.emailTextInput} sx={{ width: '100%', marginTop: '30px', marginBottom: '50px' }} label="Email Address" id="" onChange={handleEmail} type='email' required={true}/>
                <TextField sx={{ width: '100%' }} label="Password" id="fullWidth" type='password' required={true} onChange={handlePassword}/>

                <div className="">
                    {credentialError && <Typography sx={{ marginTop: '15px', color:'red' }}><h4>{credentialErrorMessage}</h4></Typography>}
                </div>

                <div className="">
                    <LoadingButton
                        color="primary"
                        sx={{ marginTop: '40px',  width:'100%', height:50}}
                        size='large'
                        loadingPosition="start"
                        startIcon={<AiOutlineLogin />}
                        variant="contained"
                        type='submit'
                        loading={loading}
                        
                    >
                        <span>Sign In</span>
                    </LoadingButton>
                    
                </div>
                </form>
                
                
            </div>
        </div>
    )
}

export default LoginPage