import React from 'react'
import Dashboard from '../../layout/dashboard/dashboardComponent'
import { Grid } from '@mui/material'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import classes from '../orders/ordersPage.module.css'
import OrdersDataTable from '../../components/ordersDataTable';
import { getSales } from '../../services/storeServices';
import { useEffect, useState } from 'react';
import LoaderDialogue from '../../components/loaderDialogueComponent';
import CancelledOrdersDataTable from '../../components/cancelledOrdersDataTable';
import { refreshToken } from '../../services/userServices';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { getCancelledOrders } from '../../services/storeServices';
import AlertComponent from '../../components/alertComponent';
import { secondaryColour } from '../../utils/colors';




function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




function OrdersPage() {
    const [value, setValue] = React.useState(0);
    const storeID = localStorage.getItem("userStoreID")
    const registerID = localStorage.getItem("registerID")
    const [allSales, setAllSales] = useState([])
    const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
    const [loaderDialogueText, setLoaderDialogueText] = useState('')
    const [cancelledOrders, setCancelledOrders] = useState([])
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    

    const navigate = useNavigate()
    let totalSaleAmount = allSales.reduce((counter,item)=> (counter+item.total_amount),0).toFixed(2)
    let totalMealPlanSaleAmount = 0
    let totalCashSaleAmount = 0
    

    const allOrders = JSON.parse(localStorage.getItem("allSales"))
    const closeAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const getAllCancelledOrders = () => {

        if (localStorage.getItem("cancelledOrders")) {
            setCancelledOrders(JSON.parse(localStorage.getItem("cancelledOrders")))
        } else {

            setOpenLoaderDialogue(true)
            setLoaderDialogueText("POS is getting your cancelled orders....")
            getCancelledOrders(localStorage.getItem("userStoreID"), localStorage.getItem("registerID")).then(
                response => {
                    setCancelledOrders(response.data)
                    localStorage.setItem("cancelledOrders", JSON.stringify(response.data))
                    setOpenLoaderDialogue(false)
                }
            ).catch(error => {
                if (error.response.status === 401) {
                    setOpenLoaderDialogue(false)
                    setOpenAlert(true)
                    setAlertMessage('Your token has expired')
                    Cookies.remove('loggedState')
                    Cookies.remove('bearer')
                    navigate('/ ')
                    localStorage.clear()
                }
                else{
                    setOpenLoaderDialogue(false)
                    setOpenAlert(true)
                    setAlertMessage('POS encountered an error. Please try again')
                    
                }
            })

        }
    }

    const getAllSales = () => {

        setOpenLoaderDialogue(true)
        setLoaderDialogueText("POS is getting your orders....")
        try {
            getSales(storeID, registerID).then(
                response => {
                    
                    setAllSales([...response.data])
                   
                    
                    setOpenLoaderDialogue(false)
                    
                }
            ).catch(error => {
               
                setOpenLoaderDialogue(false)
                
                    setOpenAlert(true)
                    setAlertMessage("Error getting sales, please try again")
                    setOpenLoaderDialogue(false)
                
                    if (error.response && error.response.status === 401) {
                        setOpenLoaderDialogue(false)
                        setOpenAlert(true)
                        setAlertMessage('Your token has expired')
                        Cookies.remove('loggedState')
                        Cookies.remove('bearer')
                        navigate('/ ')
                        localStorage.clear()
                    }
                    else{
                        console.log(error)
                        console.log(error)
                        setOpenLoaderDialogue(false)
                        setOpenAlert(true)
                        setAlertMessage('POS encountered an error. Please try again')
                        
                    }
            })
        } catch (error) {
            openAlert(true)
            setAlertMessage("Error getting sales, please try again")
            setOpenLoaderDialogue(false)
        }
        
    }


   
    useEffect(() => {
        getAllSales()
        const storeLogger= localStorage.getItem('userStoreID')
        if(!storeLogger){
            navigate("/select")
        }
        

    }, [])

    
    let cashSaleAmount = 0
    for (let index = 0; index < allSales.length; index++) {
        if(allSales[index].payment_method === "Cash"){
            cashSaleAmount+=allSales[index].total_amount
        }
        
    }

    let mealPlanSaleAmount = 0
    for (let index = 0; index < allSales.length; index++) {
        if(allSales[index].payment_method === "Credit"){
            mealPlanSaleAmount+=allSales[index].total_amount
        }
        
    }

    

    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Dashboard>
            <Grid container spacing={2} sx={{ p: 3, width: "95%" }} >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <div className="">
                                <h3 style={{color:secondaryColour}}>Orders</h3>
                            </div>

                            <div className="">
                                <h1 style={{color:secondaryColour}}>{allSales.length}</h1>
                            </div>
                        </CardContent>
                        <CardActions>
                            <Button size="small">:</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <div className="">
                                <h3 style={{color:secondaryColour}}>Total Sales</h3>
                            </div>

                            <div className="">
                                <h1 style={{color:secondaryColour}}>GH₵ {totalSaleAmount}</h1>
                            </div>
                        </CardContent>
                        <CardActions>
                            <Button size="small">:</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <div className="">
                                <h3 style={{color:secondaryColour}}>Meal Plan</h3>
                            </div>

                            <div className="">
                                <h1 style={{color:secondaryColour}}>GH₵ {parseFloat(mealPlanSaleAmount).toFixed(2)}</h1>
                            </div>
                        </CardContent>
                        <CardActions>
                            <Button size="small">:</Button>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <div className="">
                                <h3 style={{color:secondaryColour}}>Cash Sales</h3>
                            </div>

                            <div className="">
                                <h1 style={{color:secondaryColour}}>GH₵ {cashSaleAmount || 0}</h1>
                            </div>
                        </CardContent>
                        <CardActions>
                            <Button size="small">:</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

            <div className={classes.dataContainer}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Sales" {...a11yProps(0)} />
                            <Tab label="Cancelled Orders" {...a11yProps(1)} onClick={getAllCancelledOrders}  />
                            
                           

                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <OrdersDataTable tableData={allSales} loadSales={getAllSales} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1} >
                        <CancelledOrdersDataTable tableData={cancelledOrders} />
                    </CustomTabPanel>

                </Box>
                
                <LoaderDialogue open={openLoaderDialogue} message={loaderDialogueText} />
                <AlertComponent open={openAlert} message={alertMessage} close={closeAlert} />
            </div>
        </Dashboard>
    )
}

export default OrdersPage