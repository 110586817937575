import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import classes from '../storeSelect/StoreSelect.module.css'
import { GiShop } from "react-icons/gi";
import { secondaryColour } from '../../utils/colors';
import { useEffect } from 'react';
import { loadStores } from '../../services/userServices';
import { useState } from 'react';
import StoreItem from '../../components/storeSelectComponent';
import LoaderDialogue from '../../components/loaderDialogueComponent';
import AlertComponent from '../../components/alertComponent';
import OpenRegisterForm from '../../components/openRegisterFormComponent';
import { refreshToken } from '../../services/userServices';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { emptyCart } from '../../state/redux/cartSlice';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';



const drawerWidth = 240;
const navItems = ['Logout'];

function DrawerAppBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [stores, setStores] = useState([])
    const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
    const [openRegister, setOpenRegister] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [loaderDialogueText, setLoaderDialogueText] = useState('')

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const closeAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };
    const closeOpenRegisterForm = () => {
        setOpenRegister(false)
        localStorage.removeItem('userStoreID')
    }
    const openOpenRegisterForm = () => {
        setOpenRegister(true)

    }
    const cart = useSelector(state =>state.cart)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>

            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;


    useEffect(

        () => {
            const Id = localStorage.getItem('userID')
            localStorage.removeItem('userStoreID')
            localStorage.removeItem('products')
            localStorage.removeItem('storeProducts')
            localStorage.removeItem('userStores')
            localStorage.removeItem('storeSettings')
            localStorage.removeItem('storeCategories')
            localStorage.removeItem('persist:root')
            localStorage.removeItem('registerID')
            localStorage.removeItem('storeName')
            localStorage.removeItem('allSales')
            localStorage.removeItem('allSubscribers')
            localStorage.removeItem('salesReports')
            localStorage.removeItem('cancelledOrders')
            localStorage.removeItem('allSalesReport')
            localStorage.removeItem('closedRegisterDetails')
            localStorage.removeItem('closedShiftData')
            localStorage.removeItem('funderReportDate')
            localStorage.removeItem('funderReport')
            
            
            

            const emptyTheCart = () => {
                dispatch(emptyCart())
            }
            //get user's stores
            const fetchStores = (Id) => {
                if (localStorage.getItem('userStores')) {
                    const userStores = JSON.parse(localStorage.getItem('userStores'))
                    setStores([...userStores])
                } else {
                    setOpenLoaderDialogue(true)
                    setLoaderDialogueText('POS is loading your stores.......')
                    loadStores(Id).then(

                        response => {

                            
                            localStorage.setItem('userStores', JSON.stringify(response.data))
                            setStores([...response.data])
                            setOpenLoaderDialogue(false)
                        }
                    ).catch(error => {
                        
                        if (error.response && error.response.status === 401) {
                            setOpenLoaderDialogue(false)
                            setOpenAlert(true)
                            setAlertMessage('Your token has expired')
                            Cookies.remove('loggedState')
                            Cookies.remove('bearer')
                            navigate('/ ')
                            localStorage.clear()
                        }
                        else{
                            setOpenLoaderDialogue(false)
                            setOpenAlert(true)
                            setAlertMessage('POS encountered an error. Please try again')
                            
                        }



                    })
                }

            }

            fetchStores(Id)
            emptyTheCart()

        },
        [])

        const logoutUser = () => {
            localStorage.clear()
                Cookies.remove("loggedState")
                Cookies.remove("bearer")
                navigate("/ ")
          }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" sx={{ backgroundColor: secondaryColour }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        Store Select
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item} sx={{ color: '#fff' }}>
                                <div className="" onClick={logoutUser}>
                                    {item}
                                </div>
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Box component="main" sx={{ p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", textAlign: 'center' }}>
                <Toolbar />
                <Typography sx={{}}>Select your shop to continue</Typography>
                <div className={classes.mainContainer}>
                    <Grid container spacing={2} display='flex' justifyContent="center" alignItems="center">
                        {stores.map((item, index) => {
                            return (

                                <StoreItem storeData={item} key={index} openRegisterForm={openOpenRegisterForm} />

                            )
                        })}
                    </Grid>
                </div>

            </Box>
            <LoaderDialogue open={openLoaderDialogue} message={loaderDialogueText} />
            <AlertComponent open={openAlert} message={alertMessage} close={closeAlert} />
            <OpenRegisterForm open={openRegister} closeRegister={closeOpenRegisterForm} />

        </Box>
    );
}

DrawerAppBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default DrawerAppBar;
