import React from 'react'
import Dashboard from '../../layout/dashboard/dashboardComponent'
import { Grid } from '@mui/material'
import classes from '../printregister/PrintRegisterPage.module.css'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { makeCaptureRequest, makeVerificationRequest } from '../../services/storeServices'
import BiometricScanDialogue from '../../components/biometricScanDialogue'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button';
import { getSales } from '../../services/storeServices'
import LoaderDialogue from '../../components/loaderDialogueComponent'





function ClosedRegisterPage
    () {


    const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
    const [loaderDialogueText, setLoaderDialogueText] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [allSales, setAllSales] = useState([])

    const navigate = useNavigate()
    const closeAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const closedRegisterDetails = JSON.parse(localStorage.getItem("closedRegisterDetails"))
    const closedShiftData = JSON.parse(localStorage.getItem("closedShiftData"))
    const printRegisterData = () => {
        window.print()
        navigate("/select")
    }

    const sortedData = [...closedShiftData].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        // Compare the names
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0; 
      });


    return (
        <Dashboard>

            <div className={classes.mainContainer}>
                <div className={classes.slipContainer}>
                    <h3 style={{textAlign:'center'}}>Register No.: {localStorage.getItem("registerID")}</h3>

                    <div className="">
                        <h3 style={{margin:"10px 0"}}>
                            Store - <span style={{fontWeight:"500"}}>{localStorage.getItem("storeName")}</span>
                        </h3>

                        <h3 style={{margin:"10px 0"}}>
                            Cashier - <span style={{fontWeight:"500"}}>{localStorage.getItem("username")}</span>
                        </h3>

                        <h3 style={{margin:"10px 0"}}>
                            Cash Sales - <span  style={{fontWeight:"500"}}> GHC {parseFloat(closedRegisterDetails.cash).toFixed(2)|| 0}</span>
                        </h3>

                        <h3 style={{margin:"10px 0"}} >
                            MealPlan Sales - <span  style={{fontWeight:"500"}}> GHC {parseFloat(closedRegisterDetails.credit).toFixed(2) || 0}</span>
                        </h3>

                        <h3 style={{margin:"10px 0"}}>
                            Cancelled Sales - <span style={{fontWeight:"500"}}>GHC {parseFloat(closedRegisterDetails.cancelled).toFixed(2)}</span>
                        </h3>

                        <h3 style={{margin:"10px 0"}}>
                            Total Sales - <span style={{fontWeight:"500"}}>GHC {parseFloat(closedRegisterDetails.credit).toFixed(2) + closedRegisterDetails.cash}</span>
                        </h3>

                        <h3>
                            Closing Date - <span style={{fontWeight:"500"}}>{closedRegisterDetails.closing_time}</span>
                        </h3>
                    </div>

                    <div className="">
                        <table className={classes.blueTable}>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Quantity</th>
                                    <th>Sale</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map(item=>{
                                   return (
                                        <tr>
                                    <td>{item.name}</td>
                                    <td>{item.total_quantity}</td>
                                    <td>GHC {parseFloat(item.total_cost).toFixed(2)}</td>
                                   
                                </tr>
                                    )
                                })}
                               
                                
                                
                            </tbody>
                            <tfoot>
                                
                            </tfoot>
                        </table>
                    </div>
                    <Button variant = "outlined" onClick={printRegisterData}>PRINT</Button>
                </div>
                <LoaderDialogue open={openLoaderDialogue} message={loaderDialogueText} />

            </div>
        </Dashboard>

    )
}

export default ClosedRegisterPage
