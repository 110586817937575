import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { IoFingerPrintOutline } from "react-icons/io5";
import { GiCheckMark } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";
import { ImCross } from "react-icons/im";

import scanLoader from "../assets/images/scanning.gif"
import unverified from "../assets/images/wrong.gif"


export default function BiometricScanDialogue(props) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading]= React.useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.close()
    };

    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="" style={{display:'flex', justifyContent:'center', marginTop:"25px", flexDirection:"column", alignItems:"center"}}>
                   
                    {
                        props.printVerified ? <GiCheckMark style={{fontSize:"80px",color:'green'}}/>: props.printNotVerified ? <ImCross style={{fontSize:"80px", color:'red'}}/>: !props.printNotVerified ?  <img src={scanLoader} alt="Your GIF" style={{width:"120px"}} /> : null

                    }
                
                

                </div>
                
                <DialogTitle id="alert-dialog-title">
                    <p style={{fontWeight:"bold"}}>
                    {props.message}
                    </p>
                  
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box sx={{ width: '100%' }}>
                           
                            {/* <LinearProgress/> */}
                            
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                    {/*<Button onClick={handleClose} autoFocus>
                        Agree
                    </Button> */}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
