import axios from 'axios'
import Cookies from 'js-cookie'
import { baseURL } from '../utils/colors'

//login service
export const logUserIn = async(email, password) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
            
            
        }
    )

    return await instance.post(`login?email=${email}&password=${password}`)
}

//service
export const loadStores = async(id) => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
        }
    )

    return await instance.get(`/get_user_store_list/${id}`)
}

export const refreshToken  = async() => {
    const instance = axios.create(
        {
            baseURL:baseURL,
            headers: {
                "Content-type":'application/json',
                "Authorization": `Bearer ${Cookies.get('bearer')}`
              },
            
            
        }
    )

    return await instance.post(`refresh`)
}
