import React from 'react'
import classes from '../cartItemComponent/cartItemComponent.module.css'
import { TbPaperBag } from 'react-icons/tb'
import { increaseQuantity, decreaseQuantity, removeItem } from '../../state/redux/cartSlice'
import { useDispatch } from 'react-redux'
import { RiDeleteBin2Line } from "react-icons/ri";
import { BsCartDash } from "react-icons/bs";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { useSelector } from 'react-redux'
import { selectCartItems } from '../../state/redux/cartSlice'
import { secondaryColour } from '../../utils/colors'
import { FaCirclePlus } from "react-icons/fa6";
import { FaCircleMinus } from "react-icons/fa6";





function CartItem(props) {
    const dispatch = useDispatch()
    const cartItems = useSelector(selectCartItems)
    const getItems = () => {
    }

    return (
        <div className={classes.cartItemContainer}>
            <button onClick={() => dispatch(removeItem(props.itemData.product_id))} className={`${classes.CartItemControlsButton} ${classes.cancelButton}`}><RiDeleteBin2Line/></button>
            <div className={classes.cartItem}>
                <div onClick = {getItems} className={classes.cartItemIconContainer}>
                    <TbPaperBag className={classes.cartItemIcon} />
                   
                </div>
                <div className={classes.cartItemDetailsContainer}>
                    <p style={{fontWeight:'bold',color:secondaryColour}}>{props.itemData.name.toLowerCase()}</p>
                    <div className={classes.cartItemDetails} style={{fontWeight:'500',color:secondaryColour}}>
                        GHC {props.itemData.cost}
                        <div className={classes.cartItemControls}>
                        
                        <button onClick={()=>dispatch(decreaseQuantity(props.itemData.product_id))}className={classes.CartItemControlsButton}><FaCircleMinus /></button>
                            <a style={{ margin: '0 10px' }}>{props.itemData.qty}</a>
                            <button onClick={()=>dispatch(increaseQuantity(props.itemData.product_id))} className={classes.CartItemControlsButton}><FaCirclePlus /></button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CartItem