import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import classes from '../components/subscriberInfoCard.module.css'

export default function SubscriberInfoCard(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dailyBalance= props.cardData.daily_spending_limit
  const totalAmount = props.cardData.amount

  return (
    <React.Fragment>
      
      <Dialog
        open={props.openCard}
        onClose={props.closeCard}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Subscriber's Balance & Information"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{fontSize:22, marginTop:1}}>
           <p>
            <span>Name:</span> {props.cardData.firstname} {props.cardData.lastname}
           </p>
           <p>
           <span>Balance:</span>   GHC {parseFloat(dailyBalance).toFixed(2)}
           </p>
           <p>
           <span>Total amount:</span> GHC {parseFloat(totalAmount).toFixed(2)}
           </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
          <Button onClick={props.closeCard} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
