import React from 'react'
import Dashboard from '../../layout/dashboard/dashboardComponent'
import { Grid } from '@mui/material'
import classes from '../allSalesReport/allSalesReportsPage.module.css'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie'
import Button from '@mui/material/Button';





function AllSalesReportsPage
    () {
    const cart = useSelector(state => state.cart)
    const navigate = useNavigate()
    const dispatch = useDispatch()




    const closeAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };



    const printRegisterData = () => {
        window.print()
    }

    const allSalesData = JSON.parse(localStorage.getItem("allSalesReport"))
    
    const groupedSalesData = allSalesData.reduce((groups, item) => {
        const key = item.funder;
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(item);
        return groups;
    }, {});
   





    return (
        <Dashboard>

            <div className={classes.mainContainer}>
                <div className={classes.slipContainer}>
                    <h3 style={{ textAlign: 'center' }}>{localStorage.getItem("storeName")}</h3>

                    <div className="">

                        {Object.entries(groupedSalesData).map(
                            
                            ([title, items]) => (
                                <div className="" style={{marginTop:"40px"}}>
                                    <h4>{title} Sales</h4>
                                     <table className={classes.blueTable}>
                                    <thead>
                                        <tr>
                                            <th>Firstname</th>
                                            <th>Lastname</th>
                                            <th>Cardcode</th>
                                            <th>Funder</th>
                                            <th>Total</th>
                                            <th>Date</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.firstname}</td>
                                                <td>{item.lastname}</td>
                                                <td>{item.card_code}</td>
                                                <td>{item.funder}</td>
                                                <td>GHC {item.total_sales.toFixed(2)}</td>
                                                <td>{item.Date}</td>

                                            </tr>
                                        ))}





                                    </tbody>
                                    <tfoot>

                                    </tfoot>
                                </table>
                                </div>
                               
                            )
                        )}
                        
                    </div>
                    <Button variant= "outlined" onClick={printRegisterData}>print</Button>
                </div>

            </div>
        </Dashboard>

    )
}

export default AllSalesReportsPage
