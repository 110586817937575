import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { cancelOrder } from '../services/storeServices';
import LoadingButton from '@mui/lab/LoadingButton';

export default function CancelOrderDialogue(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const orderID = props.tableData.orderid

  const deleteOrder = () => {
    setLoading(true)
    cancelOrder(orderID).then(
        response=>{
            if(response.data[0].order_status === "deleted"){
              setLoading(false)
              alert("Order Cancelled")
              handleClose()
              props.loadSales()
            }
           
        }
    )
  }

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Cancel
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to cancel this order?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Canceling will refund the amount
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <LoadingButton
          size="small"
          onClick={deleteOrder}
          
          loading={loading}
          loadingPosition="end"
          variant="outlined"
        >
          <span>Yes</span>
        </LoadingButton>

          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
