import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

export default function AllSalesReportDialogue(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open alert dialog
            </Button> */}
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Store Name
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box sx={{ width: '100%' }}>
                            {/* <LinearProgress /> */}
                        </Box>
                        
                    </DialogContentText>
                    {/* {
                            props.reportData.map(
                                item=>
                                    (
                                        
                                        <div className="" style={{borderBottom:"2px solid black", marginBottom:"5px"}}>
                                            <div className="">
                                                <p>Date</p>
                                                <p></p>
                                                <p></p>
                                            </div>
                                            <div className="">
                                              Category: 
                                            </div>
                                            <div className="">
                                               Total Sales: GHC 
                                            </div>
                                            <div className="">
                                               Date:
                                            </div>
                                        </div>
                                    )
                                
                            )
                        } */}
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button>*/}
                    <Button onClick={handleClose} autoFocus>
                        Print
                    </Button> 
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
