import React from 'react'
import Dashboard from '../../layout/dashboard/dashboardComponent'
import { Grid } from '@mui/material'
import ProductItem from '../../components/itemComponent'
import classes from '../pos/PosPage.module.css'
import OrderSummary from '../../components/orderSummary'
import { BsSearch } from "react-icons/bs";
import IconChips from '../../components/foodCategoryComponent'
import Button from '@mui/material/Button';
import CartItem from '../../components/cartItemComponent/cartItemComponent'
import { baseColour } from '../../utils/colors'
import { loadStoreProducts } from '../../services/productServices'
import { useEffect, useState } from 'react'
import AlertComponent from '../../components/alertComponent'
import LoaderDialogue from '../../components/loaderDialogueComponent'
import { loadStoreSettings } from '../../services/productServices'
import { loadProductCategories } from '../../services/productServices'
import AllFoodCategoriesChip from '../../components/allFoodCategoriesComponent'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getExpectedCash } from '../../services/storeServices'
import Cookies from 'js-cookie'
import { secondaryColour } from '../../utils/colors'
import { TbTrashOff } from "react-icons/tb";
import { useDispatch } from 'react-redux'
import { emptyCart } from '../../state/redux/cartSlice'
import { IoSearchCircleSharp } from "react-icons/io5";




function POSPage
    () {

    const [productData, setProductData] = useState([])
    const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
    const [loaderDialogueText, setLoaderDialogueText] = useState('')
    const [storeProductCategories, setStoreProductCategories] = useState([])
    const [searchText, setSearchText] = useState(null)
    const [categoryID, setCategoryID] = useState()
    const [disableCheckoutButton, setDisableCheckoutButton] = useState(true)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    let cart = useSelector(state => state.cart)

    useEffect(() => {
        const storeID = localStorage.getItem('userStoreID')
        const loadProducts = (ID) => {
            getStoreCategories()
            if (localStorage.getItem('storeProducts')) {
                const storeProducts = JSON.parse(localStorage.getItem('storeProducts'))
                setProductData([...storeProducts])

            }
            else {
                setOpenLoaderDialogue(true)
                setLoaderDialogueText('POS is setting up your store.....')
                loadStoreProducts(ID).then(
                    response => {
                        
                        localStorage.setItem('storeProducts', JSON.stringify(response.data))
                        setProductData([...response.data])
                        getStoreSettings()
                        getStoreCategories()


                    }
                ).catch(error => {
                   
                    // setOpenLoaderDialogue(false)
                    if (error.response.status === 401) {
                        setOpenLoaderDialogue(false)
                        setOpenAlert(true)
                        setAlertMessage('Your token has expired')
                        Cookies.remove('loggedState')
                        Cookies.remove('bearer')
                        navigate('/ ')
                        localStorage.clear()
                    }
                    else{
                        setOpenLoaderDialogue(false)
                        setOpenAlert(true)
                        setAlertMessage('POS encountered an error. Please try again')
                        
                    }
                })
            }

        }

        if (cart.length > 0) {
            setDisableCheckoutButton(false)
        } else {
            setDisableCheckoutButton(true)
        }

        loadProducts(storeID)
    }, [cart])


    const getTotalQuantity = () => {
        let total = 0
        cart.forEach(element => {
            total += element.qty
        });
        
        return total
    }


    const getTotal = () => {
        let totalQuantity = 0
        let totalPrice = 0

        cart.forEach(item => {
            totalQuantity += item.qty
            totalPrice += item.cost * item.qty
        })
        return { totalPrice, totalQuantity }
    }
    const getStoreSettings = () => {
        const storeID = localStorage.getItem('userStoreID')
        loadStoreSettings(storeID).then(
            response => {
                localStorage.setItem('storeSettings', JSON.stringify(response.data))
            }
        ).catch(error => {
            if (error.response.status === 401) {
                setOpenLoaderDialogue(false)
                setOpenAlert(true)
                setAlertMessage('Your token has expired')
                Cookies.remove('loggedState')
                Cookies.remove('bearer')
                navigate('/ ')
                localStorage.clear()
            }
            else{
                setOpenLoaderDialogue(false)
                setOpenAlert(true)
                setAlertMessage('POS encountered an error. Please try again')
                
            }
        })
    }

    const getStoreCategories = () => {
        const storeCategories = localStorage.getItem('storeCategories')
        if (storeCategories) {
            const storeProductCategories = JSON.parse(localStorage.getItem('storeCategories'))
            setStoreProductCategories([...storeProductCategories])
        } else {
            const storeID = localStorage.getItem('userStoreID')
            loadProductCategories(storeID).then(

                response => {
                    localStorage.setItem('storeCategories', JSON.stringify(response.data))
                    setStoreProductCategories([...response.data])
                    setOpenLoaderDialogue(false)
                }
            ).catch(error => {
                if (error.response.status === 401) {
                    setOpenLoaderDialogue(false)
                    setOpenAlert(true)
                    setAlertMessage('Your token has expired')
                    Cookies.remove('loggedState')
                    Cookies.remove('bearer')
                    navigate('/ ')
                    localStorage.clear()
                }
                else{
                    setOpenLoaderDialogue(false)
                    setOpenAlert(true)
                    setAlertMessage('POS encountered an error. Please try again')
                    
                }
            })
        }

    }

    const handleSearch = (event) => {
        const searchValue = event.target.value.trim()
        localStorage.setItem("searchItem",searchValue)
        setSearchText(searchValue)

    }

    const getCategoryID = (productCategoryID) => {
        setCategoryID(productCategoryID)
    }

    const expectedcash = () => {
        getExpectedCash(localStorage.getItem('userStoreID'), localStorage.getItem('registerID')).then(
            response => {  }
        )
    }

    useEffect(
        ()=>{
            setSearchText(localStorage.getItem("searchItem"))
            const storeLogger= localStorage.getItem('userStoreID')
            if(!storeLogger){
                navigate("/select")
            }
        }
    )

    return (
        <Dashboard>

            <div className={classes.mainContainer}>

                <Grid container spacing={2}>
                    {/* Product Grid */}

                    <Grid item xs={6} md={8} lg={9} sx={{ backgroundColor: '', }}>

                        <Grid container spacing={2} sx={{ padding: 3 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                
                                <div className={classes.searchContainer}>
                                    <div className={classes.search}>
                                        <input type="text" name="" id="" onChange={handleSearch} value={searchText}/>
                                    </div>
                                    <div className={classes.searchIcon}>
                                        <IoSearchCircleSharp style={{fontSize:"40px"}}/>
                                    </div>
                                </div>
                                <div className={classes.foodCategoryContainer}>
                                    <AllFoodCategoriesChip getCategoryID={getCategoryID} />
                                    {storeProductCategories.map((item, index) => {
                                        return (
                                            <IconChips data={item} key={index} getCategoryID={getCategoryID}></IconChips>
                                        )
                                    })}


                                </div>

                            </Grid>

                            {/* product item container */}
                            <Grid container spacing={2} sx={{ p: 2, overflow: 'auto', height: '690px', marginTop: '10px' }}>
                                {searchText !== null && searchText !== undefined && searchText.trim() !== '' ?
                                    productData.filter((item, index) => item.name && item.name.toLowerCase().includes(searchText.toLowerCase())).map((item, index) => {
                                        return (
                                            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                                <ProductItem data={item} />
                                            </Grid>
                                        )
                                    }) :
                                    categoryID ?
                                        productData.filter((item, index) => item.category_id == categoryID).map((item, index) => {
                                            return (
                                                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                                    <ProductItem anotherdata={name} data={item} />
                                                </Grid>
                                            )
                                        }) :
                                        productData.map((item, index) => {
                                            return (
                                                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                                    <ProductItem data={item} />
                                                </Grid>
                                            )
                                        })
                                }

                            </Grid>
                        </Grid>
                    </Grid>


                    {/* Cart Grid */}
                    <Grid item xs={6} md={4} lg={3} sx={{ backgroundColor: 'white', height: 'auto', p: 3 }}>
                        <div className={classes.cartHeader}>
                            <h3 data-testid="orderCount" style={{color:secondaryColour}}>Orders - {getTotalQuantity() || 0}</h3>
                            <div className="" onClick={()=>{
                                 dispatch(emptyCart())
                            }}><TbTrashOff style={{fontSize:"23px", color:'red'}}/></div>
                        </div>
                        
                        <div className={classes.cartContainer}>
                            <div className={classes.cartItemContainer}>
                                {
                                    [...cart].reverse()?.map(
                                        item => (
                                            item && <CartItem key={item.product_id} itemData={item} />
                                        )
                                    )
                                }

                            </div>

                            <div className={classes.orderSummary}>

                                <OrderSummary></OrderSummary>
                            </div>
                        </div>

                        <div className="">
                            <div className={classes.summaryDetails}>
                                <div className=""><h4 style={{color:secondaryColour}}>Total</h4></div>
                                <div className=""><h4 style={{color:secondaryColour}}>GHC {getTotal().totalPrice.toFixed(2)}</h4></div>
                            </div>
                            <div className={classes.summaryDetails}>
                                {/* <div className=""><h4>Total</h4></div>
                                <div className=""><h4>GHC 40</h4></div> */}
                            </div>

                            <div className="">
                                <Button disabled={disableCheckoutButton} variant="contained" disableElevation sx={{ width: '100%', height: 50, backgroundColor: baseColour }} onClick={() => { navigate("/checkout") }}>
                                    Proceed to checkout
                                </Button>
                            </div>

                        </div>
                    </Grid>
                </Grid>
                
                <AlertComponent></AlertComponent>
                <LoaderDialogue open={openLoaderDialogue} message={loaderDialogueText} />
            </div>
        </Dashboard>

    )
}

export default POSPage
