import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function AlertComponent(props) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={props.close}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <div>
      {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
      <Snackbar
        open={props.open}
        autoHideDuration={8000}
        onClose={props.close}
        message={props.message}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Set anchor origin to top-right corner
       
      />
    </div>
  );
}
