import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { TbPaperBag } from "react-icons/tb";
import classes from '../pages/storeSelect/StoreSelect.module.css'
import styleClasses from '../components/itemComponent.module.css'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';
import { IoFastFoodSharp } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import { addToCart } from '../state/redux/cartSlice';

export default function ProductItem(props) {

    const dispatch = useDispatch()
    return (

        <Card data-testid="product-container" onClick={() => dispatch(addToCart({ product_id: props.data.id, name: props.data.name, cost: props.data.price }))} sx={{ maxWidth: 250, height: 'auto', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset' }}>
            <CardMedia sx={{ height: 180, padding: '10px' }} title={props.data.name}>

                <CardContent sx={{ height: '165px', backgroundColor: props.data.color_code, borderRadius: '5px' }}>
                    <Typography variant="h6" component="div" sx={{ color: 'white', fontWeight: 'bold' }}>
                        GHC{props.data.price}
                    </Typography>
                    <div className={styleClasses.itemHeaderContent}>
                        <div className={`${classes.iconContainerDiv} ${styleClasses.cardHeader}`}>
                            <TbPaperBag className={`${classes.storeicon} ${styleClasses.itemIcon}`} />
                        </div>
                        <Typography gutterBottom variant="h6" component="div" sx={{ marginTop: 3, overflowWrap: 'break-word', fontSize: 15.5, textAlign: 'center', fontWeight: 'bold' ,color:"rgb(7, 104, 149)"}}>
                            {props.data.name}
                        </Typography>

                    </div>
                </CardContent>
            </CardMedia>
            <CardContent>

                <Typography variant="h5" color="text.secondary">
                </Typography>
            </CardContent>
            <CardActions>


            </CardActions>
        </Card>
    );
}