import React from 'react'
import Dashboard from '../../layout/dashboard/dashboardComponent'
import { Grid } from '@mui/material'
import classes from '../printregister/PrintRegisterPage.module.css'
import { loadStoreProducts } from '../../services/productServices'
import { useEffect, useState } from 'react'
import AlertComponent from '../../components/alertComponent'
import LoaderDialogue from '../../components/loaderDialogueComponent'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button';






function SummaryReportsPage
    () {


    const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
    const [loaderDialogueText, setLoaderDialogueText] = useState('')
    const [cardCode, setCardCode] = useState("")
    const [cardPin, setCardPin] = useState("")
    const [subscriberName, setSubscriberName] = useState('-')
    const [subscriberBalance, setSubscriberBalance] = useState('')
    const [amountPaid, setAmountPaid] = useState("")
    const [paymentMethod, setPaymentMethod] = useState('Credit')
    const [showMealPlanForm, setShowMealPlanForm] = useState(true)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [disablePinInput, setDisablePinInput] = useState(true)
    const [loading, setLoading] = useState(false)
    const [change, setChange] = useState(0)
    const [disableOrderButton, setDisableOrderButton] = useState(true)
    const [storeSettings, setStoreSettings] = useState({})
    const [orderNumber, setOrderNumber] = useState(0)
    const [scannedBiometricData, setScannedBiometricData] = useState("")
    const [showBiometricForm, setShowBiometricForm] = useState(false)
    const [openBiometricScanDialogue, setOpenBiometricScanDialogue] = useState(false)
    const [biometricScanDialogueText, setBiometricScanDialogueText] = useState("")
    const [subscriberIsReadyForTransaction, setSubscriberIsReadyForTransaction] = useState(false)
    const [subscriberBioData, setSubscriberBioData] = useState([])
    const [disableFingerCapture, setDisableFingureCapture] = useState(true)
    const [biometricVerificationPassed, setBiometricVerificationPassed] = useState(false)
    const [biometricVerificationFailed, setBiometricVerificationFailed] = useState(false)


    const cart = useSelector(state => state.cart)
    const navigate = useNavigate()
    const dispatch = useDispatch()




    const closeAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    const summaryData = JSON.parse(localStorage.getItem("funderReport"))
    const summaryDate = localStorage.getItem("funderReportDate")
    const printRegisterData = () => {
        window.print()
    }




    return (
        <Dashboard>

            <div className={classes.mainContainer}>
                <div className={classes.slipContainer}>
                    <h3 style={{textAlign:'center'}}>{localStorage.getItem("storeName")}</h3>

                    <div className="">
                        <table className={classes.blueTable}>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Total Sales</th>
                                    <th>Date</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {summaryData.map(item=>{
                                   return (
                                        <tr>
                                    <td>{item.funder}</td>
                                    <td>GHC {item.total}</td>
                                    <td>{summaryDate}</td>
                                   
                                </tr>
                                    )
                                })}
                               
                                
                                
                            </tbody>
                            <tfoot>
                                
                            </tfoot>
                        </table>
                    </div>
                    <Button variant= "outlined"onClick={printRegisterData}>print</Button>
                </div>
                
            </div>
        </Dashboard>

    )
}

export default SummaryReportsPage
