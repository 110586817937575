import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { closeRegister } from '../services/storeServices';
import { useState } from 'react';
import { printShift } from '../services/storeServices';
import { useNavigate } from 'react-router-dom';
import ShiftsReportDialogue from './shiftsReportDialogue';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { getSales } from '../services/storeServices';
import LoaderDialogue from './loaderDialogueComponent';
import { LoadingButton } from '@mui/lab';



export default function CloseRegisterForm(props) {
    const [open, setOpen] = React.useState(false);
    const [closingCash, setClosingCash] = useState(0)
    const [notes, setNotes] = useState("")
    const [registerDetails, setRegisterDetails] = useState({})
    const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
    const [loaderDialogueText, setLoaderDialogueText] = useState('')
    const [tableData, setTableData] = useState([])
    const [openReportDialogue, setOpenReportDialogue] = useState(false)
    const[allSales, setAllSales] = useState([])
    const [loading, setLoading] = useState(false)


    const registerID = localStorage.getItem('registerID')
    const totalSales = props.cashExpected?.totalSales ?? 0;
    const openingCash = props.cashExpected?.openingCash ?? 0;
    const cashsales = props.cashExpected?.cashsales ?? 0;
    const navigate = useNavigate()
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }

    const closeForm = () => {
        props.close()
    }
    const closeReportDialogue = () =>{
        setOpenReportDialogue(false)
    }
    const handleForm = (event) => {
        event.preventDefault()
        setLoading(true)
        closeRegister(registerID, closingCash, totalSales, notes).then(
            response => {
                if (response.data.closed_shift === "True") {
                    localStorage.setItem("closedRegisterDetails", JSON.stringify(response.data))
                    setLoading(false)
                    alert("Register closed")
                    setOpenLoaderDialogue(true)
                    setLoaderDialogueText("POS is getting your shift report....")
                    printShift(registerID).then(
                        response=> {
                          if(response.status == 200){
                            setOpenLoaderDialogue(false)
                            localStorage.setItem("closedShiftData",JSON.stringify(response.data))
                            navigate("/printclosedregister")
                            // setOpenLoaderDialogue(false)
                            
                            
                          }
                          
                        }
                      ).catch(
                        error=>{
                          if(error.response && error.response.status === 401){
                            navigate("/")
                          }
                        }
                      )

                }
            }
        ).catch(
            error=>{
              if(error.response && error.response.status === 401){
                navigate("/")
              }
            }
          )
        
    }

    const getClosingBalance = (event) => {
        setClosingCash(event.target.value)
    }

    const getNotes = (event) => {
        setNotes(event.target.value)
    }

    

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={closeForm}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        handleClose();
                    },
                }}
            >
                <DialogTitle>Close Register</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className="" style={{ marginBottom: "5px" }}>
                            <h3>Opening Balance</h3>
                            {openingCash.toFixed(2)}
                        </div>

                        <div className="" style={{ marginBottom: "5px" }}>
                            <h3>Expected Total Balance</h3>
                            {totalSales.toFixed(2)}
                            
                        </div>

                        <div className="" style={{ marginBottom: "5px" }}>
                            <h3>Expected Cash Balance</h3>
                            {cashsales.toFixed(2)}
                        </div>




                    </DialogContentText>
                    <form >
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            name="balance"
                            label="Enter Closing Balance"
                            type="number"
                            fullWidth
                            variant="standard"
                            onChange={getClosingBalance}
                        />


                        <TextField
                            autoFocus

                            margin="dense"
                            id="name"
                            name="email"
                            label="Notes"
                            type="text"
                            fullWidth
                            variant="standard"
                            multiline
                            rows={5}
                            onChange={getNotes}

                        />
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <LoadingButton type="submit" onClick={handleForm} loading={loading}>Close register</LoadingButton>
                        </DialogActions>
                    </form>

                </DialogContent>

            </Dialog>
            {/* <ShiftsReportDialogue open={openReportDialogue} tableData={tableData} registerDetails={registerDetails} close={closeReportDialogue} /> */}
            <LoaderDialogue open={openLoaderDialogue} message={loaderDialogueText} />
        </React.Fragment>
    );
}