import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import LoaderDialogue from './loaderDialogueComponent';
import { useState } from 'react';
import { getFundersReport, getAllSalesReport} from '../services/storeServices';
import SummaryReportDialogue from './summaryReportDialogueComponent ';
import { HiOutlineDocumentDownload } from "react-icons/hi";
import AllSalesReportDialogue from './allSalesReportDialogueComponent';
import { useNavigate } from 'react-router-dom';





export default function ReportsDataTable(props) {
  const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
  const [loaderDialogueText, setLoaderDialogueText] = useState("")
  const [openSummaryReportDialogue, setOpenSummaryReportDialogue] = useState(false)
  const [openAllSalesReportDialogue, setOpenAllSalesReportDialogue] = useState(false)
  const [summaryData, setSummaryData] = useState ([])
  const [allSalesReportData, setAllSalesReportData] = useState ([])
  const storeID = localStorage.getItem("userStoreID")

  const navigate = useNavigate()
  const rows = props.tableData.map((item, index) => ({
    id: index += 1,
    date: item.date,
    cashsale: item.cash,
    mealplan: item.credit.toFixed(2),
    total: item.total,
    print: "",
    printall: "",
  }));

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'date', headerName: 'Date', width: 200 },
  { field: 'cashsale', headerName: 'Cash Sales', width: 200 },
  {
    field: 'mealplan',
    headerName: 'Meal Plan Sales',
    type: 'string',
    width: 200,
  },
  {
    field: 'total',
    headerName: 'Total Sales',
    type: 'string',
    width: 200,
  },
  {
    field: 'print',
    headerName: 'Print Summary',
    width: 200,
    renderCell: (params) => (
      <Button variant="outlined" startIcon={<HiOutlineDocumentDownload />} onClick={()=>generateFunderReport(params.row.date,storeID)}>
        Summary
      </Button>
    ),
  },
  {
    field: 'printall',
    headerName: 'Print All Sales',
    width: 200,
    renderCell: (params) => (
      <Button variant="outlined" startIcon={<HiOutlineDocumentDownload />} onClick={()=>generateAllSalesReport(params.row.date,storeID)}>
        All Sales
      </Button>
    ),
  },

  
];

 

  const generateFunderReport = (date, storeID) => {
    setOpenLoaderDialogue(true)
    setLoaderDialogueText("POS is generating your report, please wait....")
    getFundersReport(date, storeID).then(
      response => {
        setOpenLoaderDialogue(false)
        setSummaryData(response.data)
        localStorage.setItem("funderReport", JSON.stringify(response.data))
        localStorage.setItem("funderReportDate", date)
        // setOpenSummaryReportDialogue(true)
        navigate("/summaryreports")
      }
    ).catch(error =>{
      if(error && error.response.status === 401){
        navigate('/')
      }
    })
  }

  const generateAllSalesReport = (date, storeID) => {
    setOpenLoaderDialogue(true)
    setLoaderDialogueText("POS is generating your report, please wait....")
    getAllSalesReport(date, storeID).then(
      response => {
        if (response.status == 200){
          setOpenLoaderDialogue(false)
          setAllSalesReportData(response.data.items)
          localStorage.setItem("allSalesReport", JSON.stringify(response.data.items))
          navigate("/salesreports")
        }
       
      }
    ).catch(error =>{
      if(error && error.response.status === 401){
        navigate('/')
      }
    })
  }

  return (
    <div className="">
      <div style={{ height: 400, width: '100%', backgroundColor: "white" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20]}
        />
      </div>
      <LoaderDialogue open={openLoaderDialogue} message={loaderDialogueText} />
      <SummaryReportDialogue open={openSummaryReportDialogue} reportData={summaryData} />
      <AllSalesReportDialogue open={openAllSalesReportDialogue} reportData={allSalesReportData}/>
    </div>

  );
}
