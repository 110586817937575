import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const cartSlice = createSlice(
    {
        name: 'cart',
        initialState:{
            cart:[]
        },

        reducers: {
            addToCart:(state, action)=>{
                const itemInCart = state.cart.find(item => item.product_id === action.payload.product_id)
                if(itemInCart){
                    itemInCart.qty++
                    console.log(state.cart)
                }else{
                    state.cart.push({...action.payload, qty:1, modifier_types:""})
                    console.log(state.cart)
                }
            },
            increaseQuantity: (state, action)=>{
                const item = state.cart.find(item=>item.product_id=== action.payload)
                item.qty++
            },
            decreaseQuantity: (state, action) => {
                const itemIndex = state.cart.findIndex(item => item.product_id === action.payload);
                if (itemIndex >= 0) {
                  const item = state.cart[itemIndex];
                  if (item.qty > 1) {
                    item.qty--;
                  } else {
                    state.cart.splice(itemIndex, 1); // Remove the item if quantity is 1
                  }
                }
              
              // Other reducers...
            },
            removeItem:(state,action)=>{
                const removeItem = state.cart.filter(item=>item.product_id!==action.payload)
                state.cart = removeItem
    
            },
            emptyCart:(state,action)=>{
                state.cart = []
            }
        }
    },
)

export const selectCart = state=>state.cart
export const selectCartItems = createSelector(
    [selectCart],
    (cart) => cart.cart
  );
export const cartReducer = cartSlice.reducer
export const {
    addToCart,
    increaseQuantity,
    decreaseQuantity,
    removeItem,
    emptyCart
} = cartSlice.actions