import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseRegisterForm from './closeRegisterForm';
import { useState } from 'react';
import { getExpectedCash } from '../services/storeServices';
import LoaderDialogue from './loaderDialogueComponent';
import { refreshToken } from '../services/userServices';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getSales } from '../services/storeServices';


const options = [
  
  'Close Register',
 
  
];

const ITEM_HEIGHT = 48;

export default function DropdownMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCloseRegisterForm,setOpenCloseRegisterForm] = useState(false)
  const [openLoaderDialogue, setOpenLoaderDialogue] = useState(false)
  const [loaderDialogueText, setLoaderDialogueText]=useState("")
  const [expectedCash, setExpectedCash] = useState({
    cashsales:0,
    openingCash:0,
    totalSales:0
  })
  const[allSales, setAllSales ] = useState([])
  
  const open = Boolean(anchorEl);
  const navigate = useNavigate()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseCloseRegisterForm = () => {
    setOpenCloseRegisterForm(false)
  }
  const storeID = localStorage.getItem("userStoreID")
  const registerID = localStorage.getItem("registerID")

  const fetchExpectedCash = () => {
    setOpenLoaderDialogue(true)
    setLoaderDialogueText("POS is loading your transactional data...")
    getExpectedCash({storeID:storeID,registerID:registerID}).then(
      response=> {
        setExpectedCash(
          {
            cashsales:response.data.cash_sales,
            openingCash:response.data.opening_cash,
            totalSales:response.data.total_sales
          }
        )
          
        setOpenLoaderDialogue(false)
        setOpenCloseRegisterForm(true)
      }
    ).catch(
      error=> {
        setOpenLoaderDialogue(false)
        if (error.response.status === 401) {
          // setOpenLoaderDialogue(false)
          // setOpenAlert(true)
          // setAlertMessage('Your token has expired')
          Cookies.remove('loggedState')
          Cookies.remove('bearer')
          navigate('/ ')
          localStorage.clear()
      }
      else{
          setOpenLoaderDialogue(false)
          setOpenAlert(true)
          setAlertMessage('POS encountered an error. Please try again')
          
      }
      }
    )
  }

  

 
  
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        
      >
        <MoreVertIcon sx={{color:'white'}} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {/* {options.map((option) => (
          
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={fetchExpectedCash}>
            {option}
          </MenuItem>
        ))} */}
        <MenuItem sx={{fontWeight:"bold"}}>
        <p>{localStorage.getItem("username")}</p>
          </MenuItem>
        <MenuItem onClick={fetchExpectedCash}>
           Close Register
          </MenuItem>
      </Menu>
      <LoaderDialogue open={openLoaderDialogue} message={loaderDialogueText} />
      <CloseRegisterForm open ={openCloseRegisterForm} close={handleCloseCloseRegisterForm} cashExpected={expectedCash}></CloseRegisterForm>
    </div>
  );
}