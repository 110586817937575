import { useState } from 'react'
import reactLogo from './assets/react.svg'
import viteLogo from '/vite.svg'
import { createTheme } from '@mui/material'
import './App.css'
import { ThemeProvider } from '@emotion/react'
import POSPage from './pages/pos/posPage'
import SubscribersPage from './pages/subscribers/SubscribersPage'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import OrdersPage from './pages/orders/ordersPage'
import LoginPage from './pages/login/LoginPage'
import StoreSelect from './pages/storeSelect/StoreSelect'
import CheckoutPage from './pages/checkout/checkoutPage'
import ShiftsPage from './pages/shifts/shiftsPage'
import ReportsPage from './pages/reports/reportsPage'
import PrintRegisterPage from './pages/printregister/printRegister'
import SummaryReportsPage from './pages/summaryreports/summarryReportsPage'
import AllSalesReportsPage from './pages/allSalesReport/allSalesReportsPage'
import ClosedRegisterPage from './pages/closedregister/closedRegister'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'cursive',
    ].join(','),
  },
});

function App() {
  ``
  return (
    <Router basename='/'>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path='/' exact element={<LoginPage />} />
          <Route path='/pos' exact element={<POSPage />} />
          <Route path='/orders' exact element={<OrdersPage />} />
          <Route path='/subscribers' exact element={<SubscribersPage />} />
          <Route path='/select' exact element={<StoreSelect />} />
          <Route path='/checkout' exact element={<CheckoutPage />} />
          <Route path='/shifts' exact element={<ShiftsPage />} />
          <Route path='/reports' exact element={<ReportsPage />} />
          <Route path='/printregister' exact element={<PrintRegisterPage />} />
          <Route path='/summaryreports' exact element={<SummaryReportsPage />} />
          <Route path='/salesreports' exact element={<AllSalesReportsPage />} />
          <Route path='/printclosedregister' exact element={<ClosedRegisterPage />} />
        </Routes>
      </ThemeProvider>
    </Router>
  )
}

export default App
