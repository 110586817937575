import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { openRegister } from '../services/storeServices';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Cookies from "js-cookie"

export default function OpenRegisterForm(props) {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const getAmount = (event) => {
    setFormData(event.target.value)
  }

  const openNewRegister = () => {
    //storeID,userID,openingCash
    setLoading(true)
    const storeID = localStorage.getItem('userStoreID')
    const userID = localStorage.getItem('userID')

    openRegister(storeID, userID, formData).then(
      response => {
        localStorage.setItem('registerID', response.data.id)
        setLoading(false)
        navigate('/pos')
      }
    ).catch(error => {
      if (error.response && error.response.status === 401) {
        Cookies.remove('loggedState')
        Cookies.remove('bearer')
        navigate('/')
      }else{
        alert("Error opening register, please try again")
        setLoading(false)
      }
    })
  }
  return (
    <React.Fragment >

      <Dialog
        open={props.open}
        onClose={props.closeRegister}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            props.closeRegister
          },
        }}

      >
        <DialogTitle sx={{ width: 500 }}>Enter opening cash </DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="cash"
            name="cashl"
            label="GHC"
            type="number"
            fullWidth
            variant="standard"
            onChange={getAmount}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit" onClick={openNewRegister} loading={loading}>Open Register</LoadingButton>
          <Button onClick={props.closeRegister}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}