import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { MdPointOfSale } from "react-icons/md";
import { CiLogout } from 'react-icons/ci';
import { CiSettings } from 'react-icons/ci';
import { MdOutlineAutoGraph } from "react-icons/md"
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import classes from '../dashboard/dashboardComponent.module.css'
import DropdownMenu from '../../components/menuComponent';
import { GoPeople } from "react-icons/go";
import { LuClipboardSignature } from "react-icons/lu";
import { FaPeopleArrows } from "react-icons/fa";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { baseColour, secondaryColour } from '../../utils/colors';
import { IoStorefrontOutline } from "react-icons/io5";
import { logOut } from '../../services/storeServices';
import { LiaStoreAltSolid } from "react-icons/lia";







// import {clearLogo} from './../../assets/henumo_transparent.png'


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Dashboard({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate()
  const handleDrawerOpen = () => {
    setOpen(true);

  };

  //log user out
  const logOut = () => {
    Cookies.remove("authToken");
    navigate("/ ");
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutUser = () => {
    localStorage.clear()
        Cookies.remove("loggedState")
        Cookies.remove("bearer")
        navigate("/ ")
  }

  return (
    <Box sx={{ display: 'flex', backgroundColor: '' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} >
        <Toolbar sx={{ background:secondaryColour}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          
          {/* <img src={clearLogo} alt="transparent logo" /> */}
          <div style={{display:"flex", alignItems:"center", gap:"10px"}}>
            <LiaStoreAltSolid style={{fontSize:"30px"}}/>
          <p>{localStorage.getItem("storeName")}</p>
          </div>
          <div className={classes.userSetting} >
            
            <div className={classes.userSettings_Menu}>
              
              {/* <Typography sx={{marginRight:2}}>POS</Typography>
              <Typography sx={{marginRight:2}}>Orders</Typography> */}
            </div>
            
            <Avatar src={"/broken-image.jpg"} />
            <DropdownMenu></DropdownMenu>
          </div>

          {/* <Stack direction="row" spacing={2}>
            <Avatar src={"/broken-image.jpg"} />
          </Stack> */}

        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/select') }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <IoStorefrontOutline style={{ fontSize: '30px', color: baseColour }} title="POS" />
              </ListItemIcon>
              <ListItemText primary='Stores' sx={{ opacity: open ? 1 : 0, color: baseColour }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/pos') }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <MdPointOfSale style={{ fontSize: '30px', color: baseColour }} title="POS" />
              </ListItemIcon>
              <ListItemText primary='POS' sx={{ opacity: open ? 1 : 0, color: baseColour }} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/orders') }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <LuClipboardSignature style={{ fontSize: '30px', color: baseColour }} title="Orders" />
              </ListItemIcon>
              <ListItemText primary='Orders' sx={{ opacity: open ? 1 : 0, color: baseColour }} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/subscribers') }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <GoPeople style={{ fontSize: '30px', color: baseColour }} title="Subscribers" />
              </ListItemIcon>
              <ListItemText primary='Subscribers' sx={{ opacity: open ? 1 : 0, color: baseColour }} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }} onClick={() => { navigate('/shifts') }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <FaPeopleArrows style={{ fontSize: '30px', color: baseColour }} title="Shifts" />
              </ListItemIcon>
              <ListItemText primary='Shifts' sx={{ opacity: open ? 1 : 0, color: baseColour }} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: 'block' }} onClick={()=>{navigate('/reports')}}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <HiOutlineDocumentReport style={{ fontSize: '30px', color: baseColour }} title="Reports" />
              </ListItemIcon>
              <ListItemText primary='Reports' sx={{ opacity: open ? 1 : 0, color: baseColour }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: 'block' }} onClick={logoutUser}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}

            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <CiLogout style={{ fontSize: '30px', color: '#05153f' }} />
              </ListItemIcon>
              <ListItemText primary='Logout' sx={{ opacity: open ? 1 : 0, color: '#6b6c73c9' }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 0, backgroundColor: '#f1f5f9', height: 'auto' }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
