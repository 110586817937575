import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import classes from '../pages/pos/PosPage.module.css'
import { useSelector } from 'react-redux';
import { secondaryColour } from '../utils/colors';



export default function AccordionExpandIcon() {
    
    const cart = useSelector(state =>state.cart)
    const getTotal=()=>{
        let totalQuantity = 0
        let totalPrice = 0
    
        cart.forEach(item=>{
            totalQuantity += item.qty
            totalPrice += item.cost * item.qty
        })
        return{totalPrice, totalQuantity}
    } 
    
    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <h4 style={{color:secondaryColour}} >Order Summary</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="">
                        <div className={classes.summaryDetails}>
                            <div className=""><h4>Discount</h4></div>
                            <div className=""><h4>GHC 0</h4></div>
                        </div>
                        <div className={classes.summaryDetails}>
                            <div className=""><h4>Subtotal</h4></div>
                            <div className=""><h4>GHC {getTotal().totalPrice.toFixed(2)}</h4></div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

        </div>
    );
}
