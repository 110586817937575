import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';
import { IoFastFoodSharp } from "react-icons/io5";
import { TbPaperBag } from "react-icons/tb";


export default function IconChips(props) {
 
  const sendCategoryID = () => {
    props.getCategoryID(props.data.product_category_id)
  }
  return (

    <Chip icon={<TbPaperBag style={{fontSize:17, color:'white'}} />} label={props.data.name} sx={{backgroundColor:props.data.color_code,fontSize:17, color:'white', margin:'10px', boxShadow:'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'}} onClick={sendCategoryID} />
  );
}
