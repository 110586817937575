import * as React from 'react';
import Typography from '@mui/material/Typography';
import classes from '../components/storeSelectComponent.module.css'
import { GiShop } from 'react-icons/gi';
import { loadStoreSettings } from '../services/productServices';
import { checkRegisterStatus } from '../services/storeServices';
import { useNavigate } from 'react-router-dom';
import OpenRegisterForm from './openRegisterFormComponent';
import { useState } from 'react';
import { Grid } from '@mui/material';

export default function StoreItem(props) {

    const [openRegister, setOpenRegister] = useState(false)
    const navigate = useNavigate()
    const getStoreSettings = () => {
        loadStoreSettings(props.storeData.id).then(
            response => {
                
            }
        )
    }

    const checkTheRegisterStatus = () => {
        const userID = localStorage.getItem('userID')
        localStorage.setItem('userStoreID', props.storeData.id)
        checkRegisterStatus(props.storeData.id, userID).then(
            response => {
                
                if (response.data.status === "success") {
                    localStorage.setItem('registerID', response.data.id)
                    localStorage.setItem('storeName', props.storeData.name)
                    navigate('/pos')
                }
                else {
                    props.openRegisterForm()
                }
            }
        ).catch(error =>{
            alert("Error Opening Register, please try again")
        })
    }
    return (

        <Grid item xs={12} sm={6} md={4} lg={3}  onClick={checkTheRegisterStatus} sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', maxWidth: '300px', minWidth: '300px', marginTop: 5, marginLeft: 5 }} className={classes.storeCard}>
            <div>
                <div className={classes.iconContainer}>
                    <div className={classes.iconContainerDiv}>
                        <GiShop className={classes.storeicon} />
                    </div>
                    <Typography sx={{ padding: 3 }}><h3>{props.storeData.name}</h3></Typography>
                </div>
                
            </div>
        </Grid>





    );
}