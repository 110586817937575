import React from 'react'
import Dashboard from '../../layout/dashboard/dashboardComponent'
import classes from '../printregister/PrintRegisterPage.module.css'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';






function PrintRegisterPage
    () {
    const navigate = useNavigate()
    const registerData = JSON.parse(localStorage.getItem("registerData"))
    const printRegisterData = () => {
        window.print()
    }
    
    const sortedData = [...registerData].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        // Compare the names
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0; 
      });

      const mealPlanSales = parseFloat(localStorage.getItem("registerMealPlanSales")).toFixed(2)


    return (
        <Dashboard>

            <div className={classes.mainContainer}>
                <div className={classes.slipContainer}>
                    <h3 style={{textAlign:'center'}}>Register No.: {localStorage.getItem("registerNumber")}</h3>

                    <div className="">
                        <h3 style={{margin:"10px 0"}}>
                            Store - <span style={{fontWeight:"500"}}>{localStorage.getItem("storeName")}</span>
                        </h3>

                        <h3 style={{margin:"10px 0"}}>
                            Cashier - <span style={{fontWeight:"500"}}>{localStorage.getItem("username")}</span>
                        </h3>

                        <h3 style={{margin:"10px 0"}}>
                            Cash Sales - <span  style={{fontWeight:"500"}}> GHC {localStorage.getItem("registerCashSales") || 0}</span>
                        </h3>

                        <h3 style={{margin:"10px 0"}} >
                            MealPlan Sales - <span  style={{fontWeight:"500"}}> GHC {mealPlanSales}</span>
                        </h3>

                        <h3 style={{margin:"10px 0"}}>
                            Cancelled Sales - <span style={{fontWeight:"500"}}>GHC {parseFloat(localStorage.getItem("registerDataRefunds")).toFixed(2)}</span>
                        </h3>

                        <h3 style={{margin:"10px 0"}}>
                            Total Sales - <span style={{fontWeight:"500"}}>GHC {localStorage.getItem("registerMealPlanSales") + localStorage.getItem("registerCashSales")}</span>
                        </h3>

                        <h3>
                            Closing Date - <span style={{fontWeight:"500"}}>{localStorage.getItem("registerDataDate")}</span>
                        </h3>
                    </div>

                    <div className="">
                        <table className={classes.blueTable}>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Quantity</th>
                                    <th>Sale</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map(item=>{
                                   return (
                                        <tr>
                                    <td>{item.name}</td>
                                    <td>{item.total_quantity}</td>
                                    <td>GHC {item.total_cost.toFixed(2)}</td>
                                   
                                </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                
                            </tfoot>
                        </table>
                    </div>
                    <Button variant = "outlined" onClick={printRegisterData}>PRINT</Button>
                </div>
                
            </div>
        </Dashboard>

    )
}

export default PrintRegisterPage
